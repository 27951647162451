import React from 'react'
import { DondeProductsID, LIBERTY_PEN_GUN, storLocModalId } from '../../utils/constants'
import { Grid, Box, Button, Stack, useTheme, Typography } from '@mui/material';
import { SectionTitle } from '../Common';
import SoldBy from './SoldBy';

const EditorHeader = ({modelId, storeId, IsIframe, modelData, handleCartBtn, productPrice, modelRefreshing, pricingRefreshing }) => {
  
  const theme = useTheme();
  
  return (
    <Grid container item spacing={4} rowSpacing={2} alignItems="flex-end">
      <Grid item xs={12} sm={9} md={8} float="left">
        <Stack direction="row" spacing={2}>
          {modelData?.seller && modelData?.seller?.storeLogo && modelData?.seller?.storeLogo?.path && (
            <Box component="img" src={modelData?.seller?.storeLogo?.path} alt="Spokbee" sx={{ maxHeight: '100px' }} />
          )}
          <SectionTitle className={DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''}>{modelData?.modelName}</SectionTitle>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        md={4}
        sx={{
          paddingTop: {
            xs: '0px !important',
            sm: '32px !important'
          }
        }}
      >
        {IsIframe && modelId == LIBERTY_PEN_GUN ? (
          ''
        ) : (
          <>
            <Grid item xs={12} md={12}>
              <SectionTitle className={DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''} sx={{ color: theme.palette.primary.dark }}>{productPrice}</SectionTitle>
              <Typography>{modelData?.modelId === storLocModalId ? 'Unlock Exclusive Savings in Cart!' : ''}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Button
                  style={{ marginTop: '20px' }}
                  id="add-to-cart"
                  variant="contained"
                  onClick={() =>
                    handleCartBtn(
                      productPrice ? eval(productPrice.slice(1) || 0) : 0,
                      modelData?.setupCharge,
                      modelData?.minorderval
                    )
                  }
                  sx={{
                    color: theme.palette.custom.white,
                    background: theme.palette.primary.dark
                  }}
                  disabled={modelRefreshing || pricingRefreshing}
                  className={DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''}
                >
                  Add to cart
                </Button>
                {!IsIframe && <SoldBy modelData={modelData} />}
              </Stack>
            </Grid>
          </>
        )}

        {storeId === storLocModalId ? (
          <Button id="visit-store" className={`Button ${DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''}`} onClick={() => navigate(`${allPaths?.COMPANY}?id=${modelId}`)}>
            Visit Store
          </Button>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default EditorHeader