import React from 'react'
import { ReactComponent as CameraControlsFree} from '../../assets/controls/camera-free.svg';
import { ReactComponent as CameraControlsUp} from '../../assets/controls/camera-up.svg';
import { ReactComponent as CameraControlsDown} from '../../assets/controls/camera-down.svg';
import { ReactComponent as CameraControlsFront} from '../../assets/controls/camera-front.svg';
import { ReactComponent as CameraControlsBack} from '../../assets/controls/camera-back.svg';
import { ReactComponent as CameraControlsLeft} from '../../assets/controls/camera-left.svg';
import { ReactComponent as CameraControlsRight} from '../../assets/controls/camera-right.svg';

const CameraControls = ({rotationCamera, cameraRorationValue}) => {
  return (
    <div className='camera-controls'> 
      <button
        className={cameraRorationValue === "free" ?  'camera-controls-item-free active' : 'camera-controls-item-free'}
      >
        <CameraControlsFree />
      </button>
      <button
        onClick={() => rotationCamera('front')}
        className={cameraRorationValue === "front" ?  'camera-controls-item active' : 'camera-controls-item'}
      >
        <CameraControlsFront />
      </button>
      <button
        onClick={() => rotationCamera('left')}
        className={cameraRorationValue === "left" ?  'camera-controls-item active' : 'camera-controls-item'}
      >
        <CameraControlsLeft />
      </button>
      <button
        onClick={() => rotationCamera('back')}
        className={cameraRorationValue === "back" ?  'camera-controls-item active' : 'camera-controls-item'}
      >
        <CameraControlsBack />
      </button>
      <button
        onClick={() => rotationCamera('right')}
        className={cameraRorationValue === "right" ?  'camera-controls-item active' : 'camera-controls-item'}
      >
        <CameraControlsRight />
      </button>
      <button
        onClick={() => rotationCamera('up')}
        className={cameraRorationValue === "up" ?  'camera-controls-item active' : 'camera-controls-item'}
      >
        <CameraControlsUp />
      </button>
      <button
        onClick={() => rotationCamera('down')}
        className={cameraRorationValue === "down" ?  'camera-controls-item active' : 'camera-controls-item'}
      >
        <CameraControlsDown />
      </button>
    </div>
  )
}

export default CameraControls