// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from '../../../menu-items';
import { useSelector } from 'react-redux';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const {admin: customer } = useSelector((state) => state.adminAuthReducer);

  const navGroups = (userRole) => {
    return menuItem(userRole).items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Fix - Navigation Group
            </Typography>
          );
      }
    });
  };

  return <Box sx={{ pt: 2 }}>{customer?.userRole && navGroups(customer?.userRole)}</Box>;
};

export default Navigation;
