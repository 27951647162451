import React, { useState } from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';

const PriceBreakdown = ({breakDownValues, Quantity}) => {
  const theme = useTheme();
  const [priceValues, setPriceValues] = useState()

  useEffect(() => {
    if(breakDownValues){
      const basePrice = breakDownValues?.base || 0;
      const holeMachining = breakDownValues?.holes || 0;
      const dogPoint = breakDownValues?.ctc || 0;
      const totalUnitPrice = basePrice + holeMachining + dogPoint
      const subTotal = totalUnitPrice * Quantity;
      const markup = breakDownValues?.Markup || 0;
      const setupFees = breakDownValues?.setup || 0;
      const markupValue = (subTotal + setupFees) * (markup/100);
      const finalTotal = subTotal + markupValue + setupFees;

      setPriceValues({
        basePrice: basePrice.toFixed(2),
        holeMachining: holeMachining.toFixed(2),
        dogPoint: dogPoint.toFixed(2),
        totalUnitPrice: totalUnitPrice.toFixed(2),
        subTotal: subTotal.toFixed(2),
        markup: markup,
        markupValue: markupValue.toFixed(2),
        setupFees: setupFees.toFixed(2),
        finalTotal: finalTotal.toFixed(2),
      })
    }
  }, [breakDownValues])

  return (
    <Box>
      <Typography variant="h6" sx={{ color: theme.palette.primary.dark }}>Price Breakdown</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><b>Quantity</b></TableCell>
              <TableCell></TableCell>
              <TableCell>{Quantity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Base Price (Per Unit)</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${priceValues?.basePrice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Hole Machining (Per Unit)</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${priceValues?.holeMachining}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Dogpoint Machining (Per Unit)</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${priceValues?.dogPoint}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Total Unit Price</b></TableCell>
              <TableCell>Base + Holes + Dogpoint</TableCell>
              <TableCell>${priceValues?.totalUnitPrice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>SubTotal</b></TableCell>
              <TableCell>Total Unit Price * Quantity</TableCell>
              <TableCell>${priceValues?.subTotal}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Setup Fee</b></TableCell>
              <TableCell>(one-time)</TableCell>
              <TableCell>${priceValues?.setupFees}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Markup</b></TableCell>
              <TableCell>% x Subtotal({priceValues?.markup}%)</TableCell>
              <TableCell>{priceValues?.markup}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Final Total</b></TableCell>
              <TableCell>SubTotal + Markup Value + Setup Fees</TableCell>
              <TableCell>${priceValues?.finalTotal}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'none',
            md: '50% 1fr'
          },
          color: '#000'
        }}
      > 
        <Box>
          <label className="boundingBoxLabel">Base Price:</label> ${breakDownValues?.base}
        </Box>
        <Box>
          <label className="boundingBoxLabel">DogPoint Price:</label> ${breakDownValues?.dogPoint}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Holes Price:</label> ${breakDownValues?.holes}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Markup Price:</label> ${breakDownValues?.Markup}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Unit Price:</label> ${breakDownValues?.price}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Quantity:</label> {Quantity}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Setup Charge:</label> ${breakDownValues?.setup}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Total Price:</label> ${breakDownValues["Total Price"]}
        </Box>
      </Box> */}
    </Box>
  )
}

export default PriceBreakdown;