  // React Core and Hooks
  import React, { useCallback, useEffect, useRef, useState, memo, useContext } from 'react';
  import { redirect, useLocation, useNavigate, Link } from 'react-router-dom';
  import { useDispatch, useSelector } from 'react-redux';

  // External Libraries
  import axios from 'axios';
  import { v4 as uuidv4 } from 'uuid';
  import { useSnackbar } from 'notistack';

  // Material UI Components and Icons
  // Material UI Components and Icons
  import { Badge, Box, Button, CircularProgress, Container, Grid, IconButton, Menu, MenuItem, Stack, styled, useTheme } from '@mui/material';
  import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';



  
  // Components
  import Configurator from '../../Components/Editor/Configurator';
  import MinOrderBanner from '../../Components/Editor/MinOrderBanner';
  import Login from '../Login/Login';

  // Custom Hooks
  import useIframe from '../../Hooks/useIframe';

  // Three.js Functions and Classes
  import { initThreeJsElements } from '../../Components/Editor/Canvas';
  import { Scene } from 'three';

  // API Utilities
  import { GET, POST } from '../../utils/apis';
  // Constants
  import { allPaths, storLocModalId, mezcoModelIds, Chromatic3DProds, DondeProductsID, EARNESTCHECKDEPS, LIBERTY_PEN_GUN, ErnestModelIds } from '../../utils/constants';
  // Helper Functions
  import { errorMessage, getConfigParamsSchema, getConfiguratorUnit } from '../../utils/helpers';
import CanvasModel from '../../Components/Editor/CanvasModel';
import { SectionTitle } from '../../Components/Common';
import { SnipcartContext } from '../../Components/Context/SnipcartProvider';
import { removeAdmin } from '../../Redux/actions/adminAuthActions';
// import { removeCustomer } from '../../Redux/actions/customerAuthActions';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    padding: '0 4px',
    color: '#FFFFFF',
    right: "-2px",
    top: "-4px"
  }
}));

  const Frame = memo((props) => {
    const { previousPath } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const IsIframe = useIframe();
    const dispatch = useDispatch();
    const modelId = new URLSearchParams(location?.search).get('id') || '';
    const storeId = new URLSearchParams(location?.search).get('storeId') || '';
    const configId = new URLSearchParams(location.search).get('configId') || '';
    const clearCache = new URLSearchParams(location.search).get('cl-cache') || false;
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { admin: loggedinUser } = useSelector((state) => state.adminAuthReducer);
    // const { customer } = useSelector((state) => state.customerAuthReducer || { admin: null });

    const scene = new Scene();
    const canvas = useRef(null); // This are Ref which holds the current values of the state
    const loadGltfRef = useRef(null); // This are Ref which holds the stable function for loading latest model
    const cameraRotationRef = useRef(null);
    const { snipcartReady } = useContext(SnipcartContext);

    // This state holds the value of the response of the onshape api data
    const [loading, setLoading] = useState(true); // BOOLEAN STATE FOR INITIAL LOAD
    const [modelRefreshing, setModelRefreshing] = useState(false); // BOOLEAN STATE FOR DETACT MODEL REFRESHING
    const [pricingRefreshing, setPricingRefreshing] = useState(false); // BOOLEAN STATE FOR DETACT MODEL REFRESHING
    const [modelData, setModelData] = useState({});
    const [productPrice, setProductPrice] = useState('');
    const [selectedMaterial, setSelectedMaterial] = useState({});
    const [availableMaterials, setAvailableMaterials] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [currentConfiguration, setCurrentConfiguration] = useState({});
    const [boundingBoxDimentions, setBoundingBoxDimentions] = useState({}); // USED ONLY FOR STOR-LOC PRODUCT
    const [materialColors, setMaterialColors] = useState([]); // USED ONLY FOR STOR-LOC PRODUCT
    // Configurator Holds the value of the onshape API configurator default values and other all information related to the configuration
    const [configurator, setConfigurator] = useState([]);
    const [configurationInputs, setConfigurationInputs] = useState({});
    const [allConfigurationInputs, setAllConfigurationInputs] = useState({});
    const [dataImg, setDataImg] = useState();
    const [loadedMaterial, setLoadedMaterial] = useState({});
    const [yintercept, setYintercept] = useState(0);
    const [slope, setSlope] = useState(0);
    const [margin, setMargin] = useState(0);
    const [loadedConfiguration, setLoadedConfiguration] = useState({});
    const [loadedColors, setLoadedColors] = useState([]);
    const [noConfiguration, setNoConfiguration] = useState(false);
    const [configurationUniqueId, setConfigurationUniqueId] = useState(null);
    const [isPriceFromOnshape, setPriceFromOnshape] = useState(null);
    // new schema state
    const [StorLocNewSchema, setStorLocNewSchema] = useState(false);
    const [productDimensions, setProductDimensions] = useState(null);
    const [lookupJSON, setLookupJSON] = useState({});
    const [parameterSchema, setParameterSchema] = useState([]);
    const [savedConfigurationDate, setSavedConfigurationDate] = useState(null);
    const [isPrivate, setIsPrivate] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [priceBreakdown, setPriceBreakdown] = useState({})
    const [cartItemsCount, setCartItemsCount] = useState(0);
    const ProductDimentionRef = useRef()
    // This state holds the value of actions during Add To Cart
    const [showAlert, setShowAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const requestCounterRef = useRef(0);
    const isMenuOpen = Boolean(anchorEl);


    const r = useSelector((state) => state.adminAuthReducer);
    // FETCH MODEL DETAILS ON INITIAL LOAD
    useEffect(() => {
      // This UseEffect Fetch the model and GLTF for first initial rendering to the DOM BY The modelId

      if (isPrivate) {
        if (!loggedinUser) {
          setShowLogin(true);
        }
      }
    }, [isPrivate, loggedinUser]);

    useEffect(() => {
      if (snipcartReady) {
        const Snip = window.Snipcart;
        const initialState = Snip.store.getState();
        setCartItemsCount(initialState.cart.items.count);
        const unsubscribe = Snip.store.subscribe(() => {
          const newState = Snip.store.getState();
          setCartItemsCount(newState.cart.items.count);
        });
  
        return () => unsubscribe();
      }
    }, [cartItemsCount, snipcartReady]);

    // REFRESH MODEL SPINNER ICONS
    useEffect(() => {
      if (
        Object.is(loadedMaterial, selectedMaterial) &&
        Object.is(loadedConfiguration, currentConfiguration) &&
        JSON.stringify(loadedColors) == JSON.stringify(materialColors)
      ) {
        setModelRefreshing(false);
        // generateImage();
      }
    }, [loadedMaterial, loadedConfiguration, loadedColors]);

    const isFirstRun = useRef(true);
    useEffect(() => {
      const isMaterialSelected = Object.keys(selectedMaterial).length > 0;
      const isConfigurationSelected = Object.keys(configurationInputs).length > 0;

      if ((isConfigurationSelected || noConfiguration) && isMaterialSelected) {
        if (isFirstRun.current) {
          updateModel();
          isFirstRun.current = false;
        } else {
          const handler = setTimeout(() => {
            updateModel();
          }, 2000);
          return () => {
            clearTimeout(handler);
          };
        }
      }
    }, [configurationInputs, selectedMaterial]);

    useEffect(() => {
      // This UseEffect Fetch the model and GLTF for first initial rendering to the DOM BY The modelId
      getModel();

      if (location?.pathname === '/store' && modelId !== storLocModalId) {
        return navigate(`${allPaths?.EDITOR}?id=${modelId}`);
      }
    }, []);

    useEffect(() => {
      console.log("useEffect ~ location?.state?.configuration:", location?.state);
    }, [location])



    const getModel = useCallback(async () => {
      try {
        let url = '';

        if (configId === '') {
          url = `${GET.EDITOR_BY_ID}/${modelId}`;
        } else {
          url = `${GET.EDITOR_BY_ID}/${modelId}/${configId}`;
        }
        url = `${url}?cl-cache=${clearCache}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${loggedinUser?.accessToken}`
          }
        });

        const { data } = response;
        if (!data?.published) {
          navigate('/page404');
          return;
        }

        setLoading(false);
        setSelectedMaterial(data?.defaultMaterial);
        setModelData({ ...data });
        setAvailableMaterials(data?.available_materials);
        setPriceFromOnshape(data?.priceFromOnshape);
        setIsPrivate(data?.isPrivate);

        if (data?.isPrivate) {
          if (!loggedinUser) {
            setShowLogin(true);
            return;
          } else if (
            loggedinUser?.userRole == 2 &&
            (loggedinUser?.permissionGroup?.includes('privateStorefront') == false ||
              loggedinUser?.customerProducts?.includes(modelId) == false)
          ) {
            setShowLogin(true);
            return;
          } else if (loggedinUser?.userRole == 3) {
            const privateStorefrontsAccess = loggedinUser?.privateStorefronts
              ?.filter((item) => item.canView == true)
              .map((item) => item?.baseFigureId);

            if (privateStorefrontsAccess.includes(modelId) == false) {
              enqueueSnackbar('You have not permission of this storefront', { variant: 'warning' });
              navigate(allPaths.HOME);
              return;
            }
          }
        }

        if (!data?.priceFromOnshape) {
          setMargin(data.margin);
          setYintercept(data.pricing.yintercept || (Array.isArray(data?.pricing) ? data?.pricing[0]?.yintercept : 0));
          setSlope(data.pricing.slope || (Array.isArray(data?.pricing) ? data?.pricing[0]?.slope : 0));
        }

        const images = data?.ProductImages?.map((x) => ({
          label: uuidv4(),
          imgPath: x?.url,
          type: x?.type,
          isHome: x?.isHome
        }));
        setProductImages(images);

        setConfigurator(data?.configuration);

        if (!(modelId === storLocModalId || Chromatic3DProds.includes(modelId) || mezcoModelIds.includes(modelId))) {
          setProductPrice(data?.defaultMaterial?.price);
        }

        if (data?.configuration?.configurationParameters?.length === 0) {
          setNoConfiguration(true);
        }

        // PROCESS THE CONFIGURATION INPUT VALUES
        // check if configuration is saved in cache
        let defaultConfigurationInputs = {};
        if(
          location?.state && location?.state?.currentConfiguration && Object.keys(location?.state?.currentConfiguration).length > 0
        ){
          defaultConfigurationInputs = location?.state?.currentConfiguration
        }else if (
          data?.cachedConfiguration &&
          data?.cachedConfiguration?.configuration &&
          Object.keys(data?.cachedConfiguration?.configuration).length > 0
        ) {
          if (data?.hasLookupfields) {
            const paramName = data?.featureScriptConf?.configurationParameters[0]?.message?.parameterId;
            const cachedData = data?.cachedConfiguration?.configuration.find((field) => field.identifier === paramName);

            defaultConfigurationInputs = JSON.parse(cachedData.value);
          } else if (modelId === storLocModalId) {
            const cachedColors = [];
            defaultConfigurationInputs['JSON_STRING'] = data?.cachedConfiguration?.configuration[0].value;
            const { FColor, DColor } = JSON.parse(
              (defaultConfigurationInputs['JSON_STRING'] = data?.cachedConfiguration?.configuration[0].value)
            );
            if (FColor) cachedColors.push(FColor);
            if (DColor) cachedColors.push(DColor);
            setMaterialColors(cachedColors);
            setStorLocNewSchema(true);
          } else {
            data?.cachedConfiguration?.configuration.forEach((field) => {
              defaultConfigurationInputs[field.identifier] = field.value;
            });
          }
        } else if (modelId === storLocModalId) {
          // IF STORLOC MODEL
          defaultConfigurationInputs['JSON_STRING'] = data?.configuration?.configurationParameters[0]?.message.defaultValue;

          const parsedJsonString = JSON.parse(defaultConfigurationInputs['JSON_STRING']);
          const initialColors = [];

          const { Drawers, FColor, DColor } = parsedJsonString;

          if (Drawers[0]?.hasOwnProperty('arrayPointIndex')) {
            if (FColor) initialColors.push(FColor.Color);
            if (DColor) initialColors.push(DColor.Color);
          } else {
            if (FColor) initialColors.push(FColor);
            if (DColor) initialColors.push(DColor);
          }

          setMaterialColors(initialColors);
          setStorLocNewSchema(!Drawers[0]?.hasOwnProperty('arrayPointIndex'));
        } else if (data?.hasLookupfields) {
          // IF HAS LOOKUP FIELDS

          defaultConfigurationInputs = JSON.parse(data?.featureScriptConf?.configurationParameters[0]?.message?.defaultValue);
        } else {
          // REST ALL STOREFRONTS
          data?.configuration?.configurationParameters?.forEach((confParam) => {
            if (confParam.type == 1826) {
              return (defaultConfigurationInputs[confParam?.message?.parameterId] =
                confParam.message.rangeAndDefault?.message?.defaultValue || confParam.message.rangeAndDefault?.message?.minValue || 0);
            } else if (confParam.type == 2550) {
              return (defaultConfigurationInputs[confParam?.message?.parameterId] = confParam.message.defaultValue == true);
            } else if (confParam.type == 105) {
              return (defaultConfigurationInputs[confParam?.message?.parameterId] = confParam.message.options[0].message.option);
            } else if (confParam.type == 872) {
              return (defaultConfigurationInputs[confParam?.message?.parameterId] = confParam.message.defaultValue);
            }
          });
        }
        setConfigurationInputs(defaultConfigurationInputs || {});

        // ADD UNITS TO CONIGURATION SCHEMA
        const defaultConfiguration = data?.configuration?.currentConfiguration?.map((confParam) => {
          const paramId = confParam?.message?.parameterId;
          let value = defaultConfigurationInputs[paramId];

          if (value && value.toString()?.endsWith(getConfiguratorUnit(confParam?.message?.units))) {
            value = value.toString().replace(getConfiguratorUnit(confParam?.message?.units), '');
          } else if (modelId !== storLocModalId) {
            value += getConfiguratorUnit(confParam?.message?.units);
          }

          return `${paramId}=${value}`;
        });

        setCurrentConfiguration(defaultConfiguration.join('&'));
      } catch (error) {
        if (error?.response?.status && error?.response?.status == 403) {
          setShowLogin(true);
          setModelData(error?.response?.data?.data);
          enqueueSnackbar(error?.response?.data?.message, { variant: 'warning' });
        } else {
          enqueueSnackbar('Oops! No Data Found!', { variant: 'warning' });
        }
        setLoading(false);
      }
    }, []);

    //define loadGltf function only once
    useEffect(() => {
      if (canvas.current) {
        const { loadGltf, rotationCameraSide, rotationCameraFree } = initThreeJsElements(canvas, scene, modelId, setDataImg);
        loadGltfRef.current = loadGltf;
        cameraRotationRef.current = rotationCameraSide;
        rotationCameraFree(setCameraRotationValue)
      }
    }, [canvas.current]);

    const [cameraRorationValue, setCameraRotationValue] = useState('free')

    const rotationCamera = (value) => {
      cameraRotationRef.current(value)
      setCameraRotationValue(value)
    }

    const buildConfigurationString = () => {
      let configuration = "";
      if (modelData?.hasLookupfields) {
        let updatedConfig = JSON.parse(JSON.stringify(configurationInputs));;

        Object.keys(updatedConfig).forEach((key) => {
          if (updatedConfig[key] === 'LPBLK') updatedConfig[key] = '';
        });
        setAllConfigurationInputs(updatedConfig);

        if (modelData?.modelId === '663c5ed91969a3312eeb96b5') {
          let conf = updatedConfig;
          const removeFields = [
            'Post_Processing__Top_hole',
            'Post_Processing__Side_hole',
            'Pin_Lock_Hole',
            'Dog_Point',
            'List_QAoAroAoJ5JICg',
            'Standard',
            'PartialThread'
          ];
          const numberVals = [
            'ThruDiameter',
            'WireDiameter',
            'PinDiameter',
            'PinOffset',
            'DogPointDiameter',
            'DogPointLength',
            'PartialThread',
            'Diameter',
            'Length'
          ];
          Object.keys(EARNESTCHECKDEPS).forEach((field) => {
            if (conf[EARNESTCHECKDEPS[field]] == '' || conf[EARNESTCHECKDEPS[field]] == false) {
              conf[field] = 0;
            }
          });

          numberVals.forEach((key) => {
            conf[key] = conf[key] && conf[key] != null && conf[key] != '' ? conf[key] * 1 : 0;
          });

          conf['flange'] = conf['flange'] === 'false' ? false : true;
          if(conf['List_QAoAroAoJ5JICg'] == 'Default'){
            conf['PartialThread'] = 0
          }

          removeFields.forEach((key) => {
            delete conf[key];
          });
          
          configuration = `${modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId}=${JSON.stringify(
            conf
          )}`;
        } else if (mezcoModelIds.includes(modelId)) {
          updatedConfig.thickness = updatedConfig['thickness'] * 1;
          configuration = `${modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId}=${JSON.stringify(
            updatedConfig
          )}`;
        } else {
          configuration = `${modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId}=${JSON.stringify(
            updatedConfig
          )}`;
        }
      } else {
        const configUnits = configurator?.currentConfiguration?.reduce((acc, confParam) => {
          acc[confParam.message.parameterId] = getConfiguratorUnit(confParam.message.units || '');
          return acc;
        }, {});

        configuration = Object.entries(configurationInputs)
          .map(([key, value]) => key + '=' + (value || '') + (configUnits[key] || '') + ';')
          .join('')
          .slice(0, -1);
      }
      return configuration
    };

    // updateModel will update the model with the new states and update the model configuration only and pass material data to the updatePricingAndMaterials
    const updateModel = useCallback(async () => {

      try {
        requestCounterRef.current += 1;
        setModelRefreshing(true);
        setPricingRefreshing(true);

        const loadedColors = [];
        const queryObj = { id: modelId, material: selectedMaterial };

        // Parse JSON if configuration is for Stor-Loc
        if (modelData?.modelId === storLocModalId && configurationInputs?.JSON_STRING) {
          const jsonFormData = JSON.parse(configurationInputs['JSON_STRING']);
          const { FColor, DColor } = jsonFormData;

          // Update colors based on schema version
          if (!StorLocNewSchema) {
            if (FColor) {
              FColor.Color = materialColors[0];
              loadedColors.push(FColor.Color);
            }
            if (DColor) {
              DColor.Color = materialColors[1];
              loadedColors.push(DColor.Color);
            }
          } else {
            if (FColor) {
              jsonFormData.FColor = materialColors[0];
              loadedColors.push(FColor);
            }
            if (DColor) {
              jsonFormData.DColor = materialColors[1];
              loadedColors.push(DColor);
            }
          }
          configurationInputs.JSON_STRING = JSON.stringify(jsonFormData);
        }

        queryObj.configuration = buildConfigurationString();
        setCurrentConfiguration(queryObj.configuration);

        const [updateResponse, modelResponse, boundingBoxResponse] = await Promise.all([
          updatePricingAndMaterials(queryObj),
          axios.post(POST.MODEL_CONFIGURATION, queryObj),
          modelData?.modelId === storLocModalId && axios.post(POST.BOUNDING_BOX, queryObj)
        ]);

        if (modelResponse?.data) {
          // const { loadGltf } = initThreeJsElements(canvas, scene, modelId, setDataImg);
          //loadGltf(modelResponse.data.gltf, modelResponse.data.material, modelId, setDataImg);

          if (loadGltfRef.current) {
            const loadGltf = loadGltfRef.current;
            loadGltf(modelResponse.data.gltf, modelResponse.data.material, modelId, setDataImg);
          }
          setConfigurationUniqueId(uuidv4());

          setLoadedConfiguration(queryObj.configuration);
          setLoadedMaterial(queryObj.material);
          setLoadedColors(loadedColors);
        }

        if (boundingBoxResponse?.data) {
          setBoundingBoxDimentions(boundingBoxResponse.data.dimensions);
        }

      } catch (e) {
        console.error(e);
        // if (previousPath === location.pathname) {
        //   enqueueSnackbar(errorMessage(), { variant: 'error' });
        // }
      } finally {
        requestCounterRef.current -= 1;
        if (requestCounterRef.current === 0) {
          setModelRefreshing(false);
          setPricingRefreshing(false);
        }
      }
    });

    const updatePricingAndMaterials = useCallback(async (queryObj) => {
      await axios
        .post(POST.UPDATE_MATERIALS, queryObj)
        .then((res) => {
          const { data } = res;
          if (isPriceFromOnshape) {

            const { parts } = data;
            let productPrice = 0;
            for (let item of parts) {
              const { name } = item


              if (name && name.startsWith('{')) {
                const parseIfValidJSON = str => {
                  try { return JSON.parse(str); } catch { return null; }
                };
                const t = name?.replace(/([{,])\s*([a-zA-Z0-9_ ]+?)\s*:/g, '$1"$2":') // Wrap keys in quotes and trim spaces
                .replace(/:\s*([a-zA-Z0-9_.]+)/g, ': $1')             // Retain numbers/values without extra quotes
                .replace(/:\s*([0-9.]+)(\s|,|})/g, ': $1$2');         // Ensure numbers remain as numbers

                const parsedName = parseIfValidJSON(t);
                setPriceBreakdown(parsedName)
                if(ErnestModelIds.includes(modelId)){
                  productPrice = parsedName["Total Price"] || 0
                  setProductPrice(!isNaN(productPrice) ? `$${productPrice.toFixed(2)}` : '$0');
                  
                }else{
                  for (let key in parsedName) {
                    if (key === 'price') {
                      const price = parsedName['price'];
                      productPrice += Number(price);
                    }
  
                    if (key === 'bounds_mm') {
                      const bounds_mm = parsedName['bounds_mm'];
                      if (Array.isArray(bounds_mm) && bounds_mm.length >= 3) {
                        setProductDimensions({
                          length: bounds_mm[0],
                          width: bounds_mm[1],
                          height: bounds_mm[2],
                          mass_kg: parsedName['mass_kg'] ?? null
                        });
                      }
                    }
                  }
                  setProductPrice(!isNaN(productPrice) ? `$${productPrice.toFixed(2)}` : '$0');
                }
              } else if (!isNaN(Number(name))) {
                setProductPrice(`$${parseFloat(parts[0]?.name).toFixed(2)}`); 
                break;
              }
            }

          } else {

            let stringBasePriceOfProduct = data?.price?.replace('$', '');
            if (stringBasePriceOfProduct == 0) {
              stringBasePriceOfProduct = productPrice?.replace('$', '') || 10;
            }
            const basePrice = parseFloat(stringBasePriceOfProduct);

            let increaseAmount = basePrice * (margin / 100);
            increaseAmount = parseFloat(increaseAmount.toFixed(2));

            // From the y = mx + c ,New price (y) = Slope * Current price + Y-intercept + Increase
            let newPrice;
            if (slope && yintercept) {
              newPrice = parseFloat(slope) * basePrice + parseFloat(yintercept) + increaseAmount;
            } else {
              newPrice = basePrice + increaseAmount;
              // <<<<<<< SCS-2
            }
            const FinalAmount = `$` + parseFloat(newPrice.toFixed(2));
            setProductPrice(FinalAmount);
            setAvailableMaterials([...(data?.availableMaterialsResult || [])]);
          }
        }).catch((error) => {
          console.log("error:", error)
          if (previousPath === location.pathname) {
            return enqueueSnackbar(errorMessage(), { variant: 'error' });
          }
        });
    });

    const handleCartBtn = (price, setupCharge, minorderval) => {
      const cartItems = Snipcart?.store?.getState()?.cart?.items?.items || [];

      //Mezco
      const mezcoItems = cartItems.filter((item) => mezcoModelIds.includes(item.metadata.basefigure_id));
      const nonMezcoItems = cartItems.filter((item) => mezcoModelIds.includes(item.metadata.basefigure_id) == false);
      const isMezcoProduct = mezcoModelIds.includes(modelId);

      // Storloc
      const isStorLocProduct = modelId == storLocModalId;
      const hasStorLocItemInCart = cartItems.some((item) => item.metadata.basefigure_id == storLocModalId);
      const hasNonStorLocItemInCart = cartItems.some((item) => item.metadata.basefigure_id != storLocModalId);

      const IsDondeProduct = DondeProductsID.includes(modelId)
      const hasDondeProductInCart = cartItems.some((item) => DondeProductsID.includes(item.metadata.basefigure_id));
      const hasNonDondeProductInCart = cartItems.some((item) => !DondeProductsID.includes(item.metadata.basefigure_id));

      if(IsDondeProduct && !loggedinUser && loggedinUser?.userRole != 3){
        enqueueSnackbar('Please Login Before Adding Donde Product into Cart', { variant: 'warning' });
        console.log("handleCartBtn ~ currentConfiguration:", currentConfiguration);
        navigate(allPaths.CUSTOMER.LOGIN , { state: { shouldNavigateBack : true, currentConfiguration: configurationInputs, modelId: modelId, prevPath: location?.pathname} })
        return false;
      }

      const getQuantity = (modelId, count) => {
        if (mezcoModelIds.includes(modelId)) {
          const quantity = count || 1;
          return { maxQuantity: quantity, minQuantity: quantity, quantity: quantity };
        }

        if (modelId === storLocModalId) {
          return { maxQuantity: 1, minQuantity: 1, quantity: count || 1 };
        }

        return { maxQuantity: 10, minQuantity: 1, quantity: count || 1 };
      };

      if (mezcoItems.length > 0 && !isMezcoProduct) {
        enqueueSnackbar('Mezco Product is already in cart, you cant add another one!', { variant: 'warning' });
        return false;
      } else if (isMezcoProduct && nonMezcoItems.length > 0) {
        enqueueSnackbar('You cant add Mezco Product in cart with another products!', { variant: 'warning' });
        return false;
      } else if (isStorLocProduct && hasNonStorLocItemInCart) {
        enqueueSnackbar("You can't add StorLoc Product in cart with another products!", { variant: 'warning' });
        return false;
      } else if (!isStorLocProduct && hasStorLocItemInCart) {
        enqueueSnackbar('StorLoc Product is already in cart, you cant add another one!', { variant: 'warning' });
        return false;
      } else if (isStorLocProduct && hasStorLocItemInCart) {
        enqueueSnackbar('Product is already in Cart, Please Process the Order!', { variant: 'warning' });
        return false;
      } else if ( hasDondeProductInCart && !IsDondeProduct){
        enqueueSnackbar('Donde Product is already in cart, you cant add from other!', { variant: 'warning' });
        return false;
      } else if ( IsDondeProduct && hasNonDondeProductInCart){
        enqueueSnackbar("You can't add Donde Product in cart with other products!", { variant: 'warning' });
        return false;
      } else {
        const productID = uuidv4();
        const snipcartItem = {
          id: productID,
          price: productPrice ? eval(productPrice.slice(1) || 0) : 0,
          url: `${process.env.REACT_APP_ENVIRONMENT != 'production' ? 'https://staging.spokbee.com/backend' : process.env.REACT_APP_API_BASEURL
            }${GET.GET_PRODUCTS}/${productID}`,
          description: modelData?.description,
          //image='https://res.cloudinary.com/q5rvi3yczj/image/upload/v1635113380/next-lattice-knob-0_zzcxmq.avif'
          name: modelData?.modelName,
          ...getQuantity(modelData?.modelId, configurationInputs?.count),
          categories: [modelData?.modelId === storLocModalId ? 'STORLOC' : ''],
          image: dataImg,
          shippable: modelData?.shippingType == "DIGITAL" ? false : true ,
          customFields: [
            {
              name: 'Material',
              type: 'readonly',
              value: selectedMaterial?.title
            },
            {
              name: 'Configuration',
              type: 'readonly',
              value: currentConfiguration
            },
            {
              name: 'itemNote',
              type: 'hidden',
              value:
                modelData?.modelId === storLocModalId
                  ? `NOTE: Shipping will be calculated when your order is complete. Freight charges vary greatly depending on distance from Illinois, size of items shipped, and if special services are required. Please call the office at 1-800-STOR-LOC if you would like a freight quote.
          \r\n If you are tax exempt, please contact the office with the required paperwork.`
                  : modelData?.minorderval > 0
                    ? `NOTE: Setup Charge added : $${modelData?.setupCharge}`
                    : ''
            }
          ],
          metadata: {
            basefigure_id: modelId,
            price: productPrice ? eval(productPrice.slice(1) || 0) : 0,
            setupCharge: modelData?.setupCharge,
            minorderval: modelData?.minorderval,
            user: loggedinUser?._id,
            // dataImg: dataImg
            isFreight: modelData?.isFreight || false,
            productImg: dataImg,
            productDimensions: ProductDimentionRef?.current?.isStorloc == true
              ? { ...productDimensions, ...ProductDimentionRef?.current?.dimentions }
              : productDimensions,
            seller: modelData?.seller,
            configurationUniqueId: configurationUniqueId,
            Configuration: currentConfiguration
          }
        };

        // Snipcart.api.cart.metadata({
        //   customer_id: customer?._id
        // });

        Snipcart.api.cart.items.add(snipcartItem).then((item) => {
          let productData = {
            id: `${productID}`,
            price: `${productPrice?.slice(1)}`,
            url: `${process.env.REACT_APP_SITEURL}/${allPaths.EDITOR}?id=${modelId}`,
            baseFigure_id: modelId,
            userId: loggedinUser?._id,
            configurationUniqueId: configurationUniqueId,
          };
          if (price + setupCharge < minorderval) {
            productData.price = minorderval;
          }

          console.log("Snipcart.api.cart.items.add ~ modelData?.shippingType:", modelData?.shippingType)
          Snipcart.api.cart.update({
            metadata: {
              customerId: loggedinUser?._id,
              shippingType: modelData?.shippingType
            }
          })
            .then(function (updatedCart) {
              console.log('Cart updated with new metadata:', updatedCart);
            })
            .catch(function (error) {
              console.error('Error updating cart metadata:', error)
            });
    
          setShowAlert(true);
          axios
            .post(POST.ADD_PRODUCT, productData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((res) => {
              const { data } = res;
              if (data?.success) {
                enqueueSnackbar(data?.message, { variant: 'success' });
                Snipcart.api.theme.cart.open()
              }
            })
            .catch((e) => {
              console.log('e', e);
              if (previousPath === location.pathname) {
                return enqueueSnackbar(errorMessage(), { variant: 'error' });
              }
            });
        });
      }
    };

    // FETCH JSON SCHEMA FOR MODELS CREAETD WITH FEATURESCRIPT
    const fetchJson = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/api/JSON/${modelData?.jsonFileName}`);
        if (!response.ok) {
          throw new Error(`Error fetching JSON: ${response.statusText}`);
        }
        const data = await response.json();
        setLookupJSON(data);
        const paramSchemaJSON = getConfigParamsSchema(data);
        setParameterSchema(paramSchemaJSON);
      } catch (error) {
        console.error('Error fetching JSON:', error.message);
      }
    };

    useEffect(() => {
      if (modelData?.jsonFileName) {
        fetchJson();
      }
    }, [modelData]);
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = (event) => {
      event.preventDefault();
      const cart = Snipcart.store.getState().cart.items;
      if (cart.count > 0) {
        cart.items.forEach((item, index) => {
          console.log(item);
          Snipcart.api.cart.items.remove(item?.uniqueId).then(function (item) {
            // console.log(item);
          });
        });
      }
      // Snipcart.api.cart.items.clear().then(function (removedItems) {
      //   console.log(removedItems);
      // });
      dispatch(removeAdmin());
      // dispatch(removeCustomer());
      setTimeout(() => {
        navigate(0);
      }, 200)
    };
  
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Link style={{ color: 'black' }} to={(loggedinUser?.userRole == 2) ? allPaths.SELLER.MY_PROFILE : allPaths.MY_PROFILE}>
          <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
        </Link>
        {loggedinUser && loggedinUser?.userRole == 2 && loggedinUser?.permissionGroup?.includes('privateStorefront') && (
          <Link style={{ color: 'black' }} to={allPaths.SELLER.USERS}>
            <MenuItem onClick={handleMenuClose}>Our Users</MenuItem>
          </Link>
        )}
        {loggedinUser  && loggedinUser?.userRole == 3 && (
          <Link style={{ color: 'black' }} to={allPaths.CUSTOMER.ORDERS}>
            <MenuItem onClick={handleMenuClose}>My Orders</MenuItem>
          </Link>
        )}
        <Link style={{ color: 'black' }} to="/logout" onClick={(event) => handleLogout(event)}>
          <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
        </Link>
      </Menu>
    );

    return (
      <>
        {(modelData?.minorderval > 0 || modelData?.setupCharge > 0) && <MinOrderBanner modelData={modelData} />}
        <Container className={DondeProductsID.includes(modelId) ? 'apply-raleway-font donde-foot-color' : ''}>
          {loading ? (
            <div className="loader-center">
              <CircularProgress />
            </div>
          ) : (
            <Box
              sx={{
                textAlign: 'left',
                marginTop: '40px',
                paddingBottom: '50px',
                paddingX: modelId === LIBERTY_PEN_GUN ? '12%' : '10%',
                [theme.breakpoints.down('sm')]: {
                  paddingX: '0',
                }
              }}
            >
              {showLogin == true && (
                <Box>
                  <Login
                    modelName={modelData?.modelName}
                    isPrivateStorefront={modelData?.isPrivate == true ? true : false}
                    sellerStoreName={modelData?.seller?.storeName}
                  />
                </Box>
              )}
              {showLogin == false && (
                <Grid container spacing={2} direction="column" >
                  <Grid container item spacing={4} rowSpacing={2} xs={12} alignItems="flex-end" >
                    <Grid item xs={DondeProductsID.includes(modelId) == false ? 12 : 6} sm={7} md={7} float="left" alignItems="center" >
                      <Stack direction="row" spacing={2}>
                        {modelData?.seller && modelData?.seller?.storeLogo && modelData?.seller?.storeLogo?.path && modelData?.hide_iframe_branding == false && (
                          <Box component="img" src={modelData?.seller?.storeLogo?.path} alt="Spokbee" sx={{ maxHeight: '100px' }} />
                        )}
                        <SectionTitle sx={{
                          fontFamily: modelId === LIBERTY_PEN_GUN ? '"Haettenschweiler", sans-serif' : '',
                          fontWeight: modelId === LIBERTY_PEN_GUN ? 'normal' : 'bold'
                        }}>
                          {/* <SectionTitle sx={{ fontFamily: '"DIN Neuzeit Grotesk W01 Bold", sans-serif', fontWeight: 'normal' }}> */}
                          {modelData?.modelName}
                        </SectionTitle>
                      </Stack>
                    </Grid>
                    {DondeProductsID.includes(modelId) && (
                      <Grid
                        item
                        xs={6}
                        sm={5}
                        md={5}
                        sx={{
                          paddingTop: {
                            xs: '0px !important',
                            sm: '32px !important'
                          },
                          flexDirection: { xs: "column", md: "row"} 
                        }}
                      >  
                        <Stack direction="column-reverse" spacing={2} sx={{
                          alignItems: "end",
                          justifyContent: 'flex-end'
                        }}>
                          <Stack columnGap={2} sx={{
                            alignItems: {xs:"flex-end", md: "center"},
                            justifyContent: 'flex-end',
                            flexWrap: "wrap",
                            flexDirection:{xs:"column", md: "row"}
                          }}>
                            <SectionTitle 
                              className={DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''} 
                              sx={{ color: theme.palette.primary.dark }}
                            >{productPrice}</SectionTitle>
                            <Button
                              style={{ float: 'right' }}
                              id="add-to-cart"
                              variant="contained"
                              onClick={() =>
                                handleCartBtn(
                                  productPrice ? eval(productPrice.slice(1) || 0) : 0,
                                  modelData?.setupCharge,
                                  modelData?.minorderval
                                )
                              }
                              sx={{
                                color: theme.palette.custom.white,
                                background: theme.palette.primary.dark,
                                maxHeight: "37px",
                                justifyContent: "space-between"
                              }}
                              disabled={modelRefreshing || pricingRefreshing}
                              className={DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''}
                            >
                              Add to cart
                            </Button>
                          </Stack>
                          <Stack direction="row" spacing={2} sx={{
                            alignItems: "center",
                            justifyContent: 'flex-end'
                          }}>
                              <Box>
                              <IconButton
                                aria-label="cart"
                                style={{
                                  outline: 'none'
                                }}
                                className="snipcart-checkout"
                                sx={{ 
                                  color: theme.palette.secondary.main,
                                  "&:hover":{
                                    backgroundColor: "#ff9000"
                                  }
                                }}
                              >
                                <StyledBadge badgeContent={cartItemsCount} color="primary">
                                  <ShoppingCartIcon className="header_text" />
                                </StyledBadge>
                              </IconButton>
                            </Box>
                            {loggedinUser && loggedinUser?.userRole == 3 && (
                              <Box>
                                <IconButton
                                  sx={{
                                    outline: 'none',
                                    width: '40px',
                                    height: '40px',
                                    // border: '2px solid #262626',
                                    color: '#262626',
                                    borderRadius: '50%',
                                    fontSize: '16px',
                                    "&:hover":{
                                      backgroundColor: "#ff9000"
                                    }
                                  }}
                                  onClick={(e) => setAnchorEl(e.currentTarget)}
                                >
                                  <StyledBadge color="primary">  
                                    {loggedinUser?.fname?.charAt(0)?.toUpperCase() || 'N'}
                                  </StyledBadge>
                                </IconButton>
                              </Box>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    sx={{
                      paddingTop: {
                        xs: '16px !important',
                        sm: '16px !important'
                      }
                    }}
                  >
                    <CanvasModel
                      modelRefreshing={modelRefreshing}
                      pricingRefreshing={pricingRefreshing}
                      canvas={canvas}
                      rotationCamera={rotationCamera}
                      cameraRorationValue={cameraRorationValue}
                      boundingBoxDimentions={boundingBoxDimentions}
                      ProductDimentionRef={ProductDimentionRef}
                      setProductDimensions={setProductDimensions}
                      StorLocNewSchema={StorLocNewSchema} 
                      jsonData = {JSON?.parse(configurationInputs?.JSON_STRING || "{}")}
                    />
                  </Grid>
                  {!configId && <Configurator
                    {...props}
                    updateModel={updateModel}
                    materialColors={materialColors}
                    setMaterialColors={setMaterialColors}
                    configurator={configurator}
                    setConfigurator={setConfigurator}
                    configurationInputs={configurationInputs}
                    setConfigurationInputs={setConfigurationInputs}
                    modelRefreshing={modelRefreshing}
                    setModelRefreshing={setModelRefreshing}
                    modelData={modelData}
                    productPrice={productPrice}
                    availableMaterials={availableMaterials}
                    currentConfiguration={currentConfiguration}
                    setCurrentConfiguration={setCurrentConfiguration}
                    cachedConfiguration={modelData?.cachedConfiguration || []}
                    configId={configId}
                    selectedMaterial={selectedMaterial}
                    setSelectedMaterial={setSelectedMaterial}
                    dataImg={dataImg}
                    lookupJSON={lookupJSON}
                    parameterSchema={parameterSchema}
                    setParameterSchema={setParameterSchema}
                    StorLocNewSchema={StorLocNewSchema}
                    allConfigurationInputs={allConfigurationInputs}
                    IsIframe={IsIframe}
                  />
                }
                </Grid>
              )}
            </Box>
        )}
        </Container>
        {renderMenu}
      </>
    );
  });

  export default Frame;
