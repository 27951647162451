import React from 'react'
import { CircularProgress, Grid, Paper, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CameraControls from './CameraControls';
import BoundingBox from './BoundingBox';


const CanvasModel = ({modelRefreshing, pricingRefreshing, canvas, rotationCamera, cameraRorationValue, boundingBoxDimentions, ProductDimentionRef, setProductDimensions, StorLocNewSchema, jsonData}) => {
  const theme = useTheme();

  return (
    <div style={{ position: 'relative' }}>
      {modelRefreshing || pricingRefreshing ? (
        <div>
          <CircularProgress
            style={{
              color: 'blue',
              position: 'absolute',
              top: '0',
              right: '0',
              margin: '2px',
              zIndex: 9
            }}
          ></CircularProgress>
        </div>
      ) : (
        <CheckIcon
          fontSize="large"
          style={{
            color: 'green',
            position: 'absolute',
            top: '0',
            right: '0',
            margin: '2px',
            zIndex: 9
          }}
        ></CheckIcon>
      )}
      <Paper
        elevation={2}
        id="canvas-wrapper"
        sx={{
          position: 'relative',
          borderRadius: '10px',
          border: `1px solid ${theme.palette.custom.gray}`,
          overflow: 'hidden'
        }}
      >
        <div ref={canvas} />
        <CameraControls rotationCamera={rotationCamera} cameraRorationValue={cameraRorationValue}/>
      </Paper>
      <Grid container={true}>
        {Object.keys(boundingBoxDimentions).length > 0 && (
          <Grid item xs={12}>
            <BoundingBox
              ProductDimentionRef={ProductDimentionRef}
              setProductDimensions={setProductDimensions}
              dimensions={boundingBoxDimentions}
              StorLocNewSchema={StorLocNewSchema}
              jsonData={jsonData}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default CanvasModel